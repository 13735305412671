export default {
  state: {
  },
  actions: {
    async getEventPools (state, params) {
      return await this._vm.$api.eventPools.getEventPools(params)
    },
    async getEventPool (state, params) {
      return await this._vm.$api.eventPools.getEventPool(params)
    },
    async postEventPool (state, params) {
      return await this._vm.$api.eventPools.postEventPool(params)
    },
    async putEventPool (state, params) {
      return await this._vm.$api.eventPools.putEventPool(params)
    },
    async deleteEventPool (state, params) {
      return await this._vm.$api.eventPools.deleteEventPool(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
