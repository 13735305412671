export default axios => ({
  getCoursePackages(params) {
    return axios.get('/admin/packages', {params})
  },
  getCoursePackage(params) {
    return axios.get(`/admin/packages/${params.id}`, {params})
  },
  postCoursePackage(params) {
    return axios.post('/admin/packages', params)
  },
  putCoursePackage(params) {
    return axios.post(`/admin/packages/${params.get('id')}`, params)
  },
  deleteCoursePackage(params) {
    return axios.delete(`/admin/packages/${params.id}`)
  },
});
