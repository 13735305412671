export default axios => ({
  getTrainings(params) {
    return axios.get('/admin/trainings', {params})
  },
  getTraining(params) {
    return axios.get(`/admin/trainings/${params.id}`, {params})
  },
  postTraining(params) {
    return axios.post('/admin/trainings', params)
  },
  putTraining(params) {
    return axios.post(`/admin/trainings/${params.get('id')}`, params)
  },
  deleteTraining(params) {
    return axios.delete(`/admin/trainings/${params.id}`)
  },
});
