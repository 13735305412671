export default axios => ({
  getBioPages(params) {
    return axios.get('/admin/bio-pages', {params})
  },
  getBioPage(params) {
    return axios.get(`/admin/bio-pages/${params.id}`, {params})
  },
  postBioPage(params) {
    return axios.post('/admin/bio-pages', params)
  },
  putBioPage(params) {
    return axios.post(`/admin/bio-pages/${params.get('id')}`, params)
  },
  deleteBioPage(params) {
    return axios.delete(`/admin/bio-pages/${params.id}`)
  },
});
