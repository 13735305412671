export default {
  state: {
    coursesAll: [],
  },
  actions: {
    async getCourses (state, params) {
      return await this._vm.$api.courses.getCourses(params)
    },
    async getCourse (state, params) {
      return await this._vm.$api.courses.getCourse(params)
    },
    async postCourse (state, params) {
      return await this._vm.$api.courses.postCourse(params)
    },
    async putCourse (state, params) {
      return await this._vm.$api.courses.putCourse(params)
    },
    async deleteCourse (state, params) {
      return await this._vm.$api.courses.deleteCourse(params)
    },
    async shareCourses (state, params) {
      return await this._vm.$api.courses.shareCourses(params)
    },
    async getCoursesAll ({ commit }, params) {
      try {
        const param = Object.assign({}, params, {
          count: 'nolimit'
        });
        if (param.with) {
          param.with.push('specialities');
        } else {
          param.with = ['specialities'];
        }

        const res = await this._vm.$api.courses.getCourses(param);
        let data = res.data.data ? res.data.data : res.data;
        data.forEach(course => {
          if (course.events) course.events = course.events.sort((a,b) => this._vm.$moment(a.start_date).diff(this._vm.$moment(b.start_date)))
        })
        commit('SET_COURSES_ALL', data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_COURSES_ALL (state, data) {
      state.coursesAll = data;
    },
  },
  getters: {
  },
};
