import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import $axios from '@/plugins/axios'
import broadcastChannel from '@/plugins/broadcastChannel'
import moment from '@/plugins/moment'
import momentTz from '@/plugins/momentTz'
import store from '@/store'
import {router} from './router'
import slugify from 'slugify'
import linkify from 'vue-linkify'
import _ from 'lodash';
import { StripePlugin } from '@vue-stripe/vue-stripe';

import { highlightText } from '@/plugins/highlightText'

import ScrollTopElement from '@/components/scroll-top-element'
import Snackbar from '@/components/snack'
import SnackbarUpdate from '@/components/snack-update'
import DownloadFile from '@/components/download-file'
import Confirm from '@/components/confirm'
import Upload from '@/components/upload'
import ImagePreview from '@/components/image-preview'

Vue.prototype.$moment = moment;
Vue.prototype.$momentTz = momentTz;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$slugify = slugify;
Vue.prototype.$_ = _;
Vue.prototype.$highlightText = highlightText;

Vue.config.productionTip = false;

Vue.component('ScrollTopElement', ScrollTopElement);
Vue.component('Snackbar', Snackbar);
Vue.component('SnackbarUpdate', SnackbarUpdate);
Vue.component('DownloadFile', DownloadFile);
Vue.component('Confirm', Confirm);
Vue.component('Upload', Upload);
Vue.component('ImagePreview', ImagePreview);

Vue.directive('linkified', linkify);

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  hidePostalCode: true,
};
Vue.use(StripePlugin, options);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  mounted() {
    $axios.checkUpdate(this);
    broadcastChannel.checkUpdate(this);
  }
}).$mount('#app')
