<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <v-snackbar :value="open"
              multi-line
              :timeout="-1"
              :color="getColor"
              class="download-file-modal"
              transition="fade-transition"
              width="400"
              :style="{'margin-bottom': calcMargin}"
              right
  >
    <div class="col text-subtitle-2 text-truncate pa-0 mb-2 font-weight-regular">
      {{file.filename}}
    </div>
    <v-progress-linear
        :value="getProgress"
        :indeterminate="(file.percentage < 0 || file.percentage > 100 )&& status ==='run'"
        height="5"
        color="white"
    ></v-progress-linear>
    <div class="d-flex justify-space-between mt-2">
      <span class="col-4 text-left pa-0">{{ status ==='run' ? formatBytes(file.loaded) : '' }}</span>
      <span class="col-4 text-center pa-0">{{ getPercentage }}</span>
      <span class="col-4 text-right pa-0">{{  status ==='run' ? `${formatBytes(file.rate)}/s` : '' }}</span>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn
          small
          v-bind="attrs"
          icon
          @click.native="close(status !== 'run')"
          class="mb-auto"
      >
        <v-icon small>minimize</v-icon>
      </v-btn>
      <v-btn
          small
          v-bind="attrs"
          icon
          @click.native="abort()"
          :disabled="status !== 'run'"
      >
        <v-icon small>file_download_off</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: function () {
        return {};
      }
    },
    index: {
      type: Number,
      default: function () {
        return 0;
      }
    },
  },
  data () {
    return {
      open: false,
      status: 'run',
    }
  },
  computed: {
    getColor() {
      switch (this.status) {
        case 'run':
          return 'primary'
        case 'processed':
          return 'primary'
        case 'canceled':
          return 'error'
        case 'completed':
          return 'success'
        default:
          return 'primary'
      }
    },
    calcMargin() {
      return (this.index*100) + 'px'
    },
    getProgress() {
      if (this.status === 'completed' || this.status === 'canceled' || this.status === 'processed') return 100
      return this.file.percentage
    },
    getPercentage() {
      if (this.status === 'completed') return 'Completed'
      if (this.status === 'canceled') return 'Aborted'
      if (this.status === 'processed') return 'Processing'
      if (this.status ==='run' && this.file.percentage < 0) return 'Preparation'
      return this.file.percentage >= 0 &&  this.file.percentage <= 100 ? `${this.file.percentage}%` : ''
    },
  },

  watch: {
    status(val) {
        if (val === 'canceled' || val === 'completed') setTimeout(() => this.close(true), 5000)
    }
  },
  mounted () {
    this.open = true;
    this.$eventBus.$on('downloadChangeStatus', this.downloadChangeStatus);
  },
  beforeDestroy(){
    this.$eventBus.$off('downloadChangeStatus', this.downloadChangeStatus);
  },
  methods: {
    close(abort = false) {
      this.open = false;
      if (abort) {
        setTimeout(() => {
          this.$store.commit('DEL_DOWNLOAD_FILE', this.file.id);
          this.$store.commit('DEL_MINIMIZE_DOWNLOAD_FILE', this.file.id);
        }, 100)
      } else {
        this.$store.commit('MINIMIZE_DOWNLOAD_FILE', this.file.id)
      }
    },
    abort() {
      this.file.controller.abort();
    },
    downloadChangeStatus({id, status}) {
      if (id === this.file.id) this.status = status;
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
  }
}
</script>
