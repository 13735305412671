<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <v-hover v-slot="{ hover }"
  >
    <v-navigation-drawer :expand-on-hover="windowWidth >= 1264 && showMenuExpand"
                         :mini-variant="windowWidth >= 1264 && showMenuExpand"
                         :permanent="windowWidth >= 1264 && showMenuExpand"
                         mini-variant-width="88"
                         app
                         clipped
                         v-model="showMenu"
                         class="elevation-0 navigation-drawer-main overflow-y-auto"
                         id="navigation-drawer-main"
                         width="220"
                         floating
                         :class="{'pr-2': hover && windowWidth >= 1264 && showMenuExpand}"
                         overlay-opacity=".2"
    >
      <v-fade-transition>
        <v-overlay v-if="hover && windowWidth >= 1264 && showMenuExpand"
                   opacity="0"
                   z-index="0"
                   class="rounded-0 linear-gradient-mini-nav"
        >
        </v-overlay>
      </v-fade-transition>
      <v-fade-transition>
        <v-card v-if="load"
                class="rounded-10 elevation-main custom-menu mr-2"
                :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : 'mb-4'"
                color="white">
          <v-list nav
                  dense
                  class="px-0"
                  expand
          >
            <template v-for="item in menuList1">
              <v-list-item v-if="!item.arrayRouts"
                           :key="item.name"
                           :to="item.children ? item.children[0].path : item.path"
                           link
                           color="success"
                           class="mx-2"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else
                            :key="item.name"
                            :group="item.group"
                            no-action
                            color="success"
                            class="expanded-group"
                            ref="expandedGroup"
              >
                <template v-slot:activator>
                  <v-list-item-icon class="mr-5">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="subItem in item.children">
                  <v-list-item link
                               :key="subItem.title"
                               :to="subItem.children ? subItem.children[0].path : subItem.path"
                               v-if="!subItem.noMenu"
                               class="pl-5 mx-2"
                  >
                    <v-list-item-icon class="mr-5">
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-light font-size-14">{{ subItem.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list>
        </v-card>
      </v-fade-transition>
      <v-fade-transition>
        <v-card v-if="load"
                class="rounded-10 elevation-main custom-menu mr-2"
                :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : 'mb-4'"
                color="white">
          <v-list nav
                  dense
                  class="px-0"
                  expand
          >
            <template v-for="item in menuList2">
              <v-list-item v-if="!item.arrayRouts"
                           :key="item.name"
                           :to="item.children ? item.children[0].path : item.path"
                           link
                           color="success"
                           class="mx-2"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else
                            :key="item.name"
                            :group="item.group"
                            no-action
                            color="success"
                            class="expanded-group"
                            ref="expandedGroup"
              >
                <template v-slot:activator>
                  <v-list-item-icon class="mr-5">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="subItem in item.children">
                  <v-list-item link
                               :key="subItem.title"
                               :to="subItem.children ? subItem.children[0].path : subItem.path"
                               v-if="!subItem.noMenu"
                               class="pl-5 mx-2"
                  >
                    <v-list-item-icon class="mr-5">
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-light font-size-14">{{ subItem.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list>
        </v-card>
      </v-fade-transition>
      <template v-slot:prepend>
        <v-fade-transition>
          <v-card class="ma-2 rounded-10 elevation-main z-index-1" color="white" v-if="backArrow"
                  :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : 'mb-4'">
            <v-list nav
                    dense
                    class="px-0"
                    expand
            >
              <v-list-item link
                           color="success"
                           class="mx-2"
                           @click="back()"
                           key="logout"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon>arrow_back_ios_new</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light font-size-14">Back</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-fade-transition>
        <v-card class="ma-2 rounded-10 elevation-main z-index-1" color="white">
          <v-list nav
                  dense
                  class="px-0 py-1"

          >
            <v-list-item dense
                         class="mx-1"
            >
              <v-list-item-avatar size="32">
                <v-avatar color="success"
                          size="32"
                >
                  <span class="white--text">{{ userInitials }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-title class="font-weight-regular font-size-14">
                  {{ user.first_name || '' }}
                  {{ ` ${user.last_name}` || '' }}
                </v-list-item-title>
                <v-list-item-subtitle class="text--disabled font-size-11 font-weight-regular">
                  {{ user.email || '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </template>
      <template v-slot:append>
        <v-fade-transition>
          <v-card v-if="load"
                  class="ma-2 mt-0 rounded-10 elevation-main"
                  color="white">
            <v-list nav
                    dense
                    class="px-0"
                    expand
            >
              <template v-for="item in bottomMenuList">
                <v-list-item v-if="!item.arrayRouts"
                             :key="item.name"
                             :to="item.children ? item.children[0].path : item.path"
                             link
                             color="success"
                             class="mx-2"
                >
                  <v-list-item-icon class="mr-5">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group v-else
                              :key="item.name"
                              :group="item.group"
                              no-action
                              color="success"
                              class="expanded-group"
                              ref="expandedGroup"
                >
                  <template v-slot:activator>
                    <v-list-item-icon class="mr-5">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-light font-size-14">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-for="subItem in item.children">
                    <v-list-item link
                                 :key="subItem.title"
                                 :to="subItem.children ? subItem.children[0].path : subItem.path"
                                 v-if="!subItem.noMenu"
                                 class="pl-5 mx-2"
                    >
                      <v-list-item-icon class="mr-5">
                        <v-icon>{{ subItem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-light font-size-14">
                          {{ subItem.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
              <v-list-item link
                           color="success"
                           class="mx-2"
                           @click="logout"
                           key="logout"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon>logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light font-size-14">Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-fade-transition>
      </template>
    </v-navigation-drawer>
  </v-hover>

</template>

<script>
export default {
  data: () => ({
    load: false,
    listGroupValue: null,
    menuModel: false,
    backArrow: false,
  }),
  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },
    showMenuExpand() {
      return this.$store.state.app.showMenuExpand;
    },
    showMenu: {
      get() {
        return this.$store.state.app.showMenu;
      },
      set(newValue) {
        if (newValue !== this.showMenu) {
          this.$store.commit('SET_SHOW_MENU');
        }
      }
    },
    menuList1() {
      return this.$store.state.app.menuList1;
    },
    menuList2() {
      return this.$store.state.app.menuList2;
    },
    bottomMenuList() {
      return this.$store.state.app.bottomMenuList;
    },
    user() {
      return this.$store.state.user.user;
    },
    userInitials() {
      return this.$store.getters.userInitials;
    },
  },
  watch: {
    '$route'(to, from) {
      this.$store.dispatch('updateMenulist');
      this.$store.dispatch('updateBottomMenulist');

      this.backWatcher(to, from);
    },
    showMenu(val) {
      if (val) this.$vuetify.goTo(0, {container: '#navigation-drawer-main', duration: 0})
    },
  },
  created() {
    this.backWatcher(this.$router.currentRoute, null);
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.load = true;
        this.$nextTick(() => {
          let el = document.getElementById('navigation-drawer-main');
          if (el) {
            let scrollTo = el.getElementsByClassName('v-list-item--active');
            if (scrollTo && scrollTo[0]) {
              this.$nextTick(() => {
                scrollTo[0].scrollIntoView({block: 'center', inline: 'nearest', behavior: 'smooth'})
              })
            }
          }
        })
      }, 300)
    })

    this.$eventBus.$on('back', this.back);
  },
  beforeDestroy() {
    this.$eventBus.$off('back', this.back);
  },
  methods: {
    logout() {
      setTimeout(() => this.listGroupValue = null)
      this.$store.dispatch('logout');
    },
    back() {
      this.$router.go(-1);
    },
    backWatcher(to) {
      this.backArrow = to.path.split('/').length > 2;
    }
  },
}
</script>
