export default {
  state: {
    adminsAll: [],
  },
  actions: {
    async getUsers (state, params) {
      return await this._vm.$api.users.getUsers(params)
    },
    async getUser (state, params) {
      return await this._vm.$api.users.getUser(params)
    },
    async postUser (state, params) {
      return await this._vm.$api.users.postUser(params)
    },
    async putUser (state, params) {
      return await this._vm.$api.users.putUser(params)
    },
    async deleteUser (state, params) {
      return await this._vm.$api.users.deleteUser(params)
    },
    async courseCompletionUser (state, params) {
      return await this._vm.$api.users.courseCompletionUser(params)
    },
    async setPassword (state, params) {
      return await this._vm.$api.users.setPassword(params)
    },
    async sendCheckoutLink (state, params) {
      return await this._vm.$api.users.sendCheckoutLink(params)
    },
    async getAdminsAll ({ commit }, params) {
      try {
        const param = Object.assign({}, params, {
          count: 'nolimit',
          role: 'admin',
        });

        const res = await this._vm.$api.users.getUsers(param);
        let data = res.data.data ? res.data.data : res.data;
        commit('SET_ADMINS_ALL', data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_ADMINS_ALL (state, data) {
      state.adminsAll = data;
    },
  },
  getters: {
  },
};
