<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <v-dialog v-model="dialogImg"
            width="auto"
            :maxWidth="clientWidth"
            content-class="img-modal"
            hide-overlay
            :style="{ maxHeight: clientHeight }"
  >
      <v-img :src="srcForDialogImg"
             :lazy-src="srcForDialogImg"
             contain
             class="title"
             @error="srcForDialogImg = '/image-not.png'"
             eager
             :maxHeight="clientHeight"
             :maxWidth="clientWidth"
      >
        <v-btn absolute
               fab
               top
               x-small
               right
               @click="dialogImg = false"
               elevation="0"
        >
          <v-icon class="text--secondary">close</v-icon>
        </v-btn>
      </v-img>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {},
  data() {
    return {
      srcForDialogImg: '',
      dialogImg: false,
      clientHeight: '90%',
      clientWidth: '90%',
    }
  },
  created() {
  },
  mounted() {
    this.load();
  },
  computed: {
    img() {
      return this.$store.state.app.srcForDialogImg;
    },
  },
  watch: {
    img(val) {
      if (val !== '') {
        setTimeout(() => this.dialogImg = true, 100);
        this.srcForDialogImg = val;
        this.$store.commit('SET_SRC_FOR_DIALOG_IMG', '')
      }
    },
  },
  methods: {
    load() {
      let el = document.getElementById('app');
      if (el) {
        this.clientHeight = (el.offsetHeight / 100) * 90;
        this.clientWidth = (el.offsetWidth / 100) * 90;
      }
    }
  }
}
</script>
