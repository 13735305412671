export default {
  state: {
  },
  actions: {
    async getBioPages (state, params) {
      return await this._vm.$api.bioPages.getBioPages(params)
    },
    async getBioPage (state, params) {
      return await this._vm.$api.bioPages.getBioPage(params)
    },
    async postBioPage (state, params) {
      return await this._vm.$api.bioPages.postBioPage(params)
    },
    async putBioPage (state, params) {
      return await this._vm.$api.bioPages.putBioPage(params)
    },
    async deleteBioPage (state, params) {
      return await this._vm.$api.bioPages.deleteBioPage(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
