export default {
  state: {
  },
  actions: {
    async getJobs (state, params) {
      return await this._vm.$api.jobs.getJobs(params)
    },
    async getJob (state, params) {
      return await this._vm.$api.jobs.getJob(params)
    },
    async postJob (state, params) {
      return await this._vm.$api.jobs.postJob(params)
    },
    async putJob (state, params) {
      return await this._vm.$api.jobs.putJob(params)
    },
    async deleteJob (state, params) {
      return await this._vm.$api.jobs.deleteJob(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
