export default {
  state: {
  },
  actions: {
    async getProducts (state, params) {
      return await this._vm.$api.products.getProducts(params)
    },
    async getProduct (state, params) {
      return await this._vm.$api.products.getProduct(params)
    },
    async postProduct (state, params) {
      return await this._vm.$api.products.postProduct(params)
    },
    async putProduct (state, params) {
      return await this._vm.$api.products.putProduct(params)
    },
    async deleteProduct (state, params) {
      return await this._vm.$api.products.deleteProduct(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
