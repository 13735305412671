export default axios => ({
  setLogin(params) {
    return axios.post(`/login`, params)
  },
  setReset(params) {
    return axios.post(`/password/reset`, params)
  },
  setForgot(params) {
    return axios.post(`/password/email`, params)
  },
  setMe() {
    return axios.get(`/admin/me`)
  },
  refreshToken(params) {
    return axios.post(`/jwt/refresh`, params)
  }
});
