<style lang="scss">
@import 'styles.scss';
</style>

<template>
    <v-app-bar fixed
               app
               clipped-left
               extension-height="5"
               class="main-toolbar-class elevation-main"
               color="white"
               height="50"
               :class="$vuetify.breakpoint.smAndUp ? 'px-5' : ''"
    >
        <v-app-bar-nav-icon v-if="windowWidth < 1264"
                            @click.stop="setShowMenu"
                            class="rounded-circle"
        >
            <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon v-else
                            @click.stop="setShowMenuExpand"
                            :style="{transform: `rotate(${showMenuExpand ? '0.5turn' : '0'})`}"
                            class="rounded-circle"
        >
            <v-icon>menu_open</v-icon>
        </v-app-bar-nav-icon>
        <v-img src="/MainLogoColour.png"
               lazy-src="/MainLogoColour.png"
               max-height="40"
               max-width="80"
               class="ml-4"
               contain
        ></v-img>
        <v-spacer></v-spacer>
      <v-fade-transition>
        <v-btn v-if="downloadedFilesLength"
               small
               color="primary"
               dark
               class="downloads-footer-button mr-4"
               elevation="1"
               @click.stop="openDownloads"
        >
                    <span>
                        {{ `${downloadedFilesLength} Download${downloadedFilesLength === 1 ? '' : 's'}` }}
                    </span>
          <v-progress-linear indeterminate
                             color="white"
          ></v-progress-linear>
        </v-btn>
      </v-fade-transition>
      <v-app-bar-nav-icon @click.stop="setShowNotificationsPanel"
                          class="rounded-circle mr-0"
      >
        <v-badge :value="!!unreadAmount"
            color="error"
            :content="unreadAmount"
            overlap
            bordered
            offset-x="10"
            offset-y="10"
        >
          <v-icon color="success">notifications</v-icon>
        </v-badge>
      </v-app-bar-nav-icon>
    </v-app-bar>
</template>

<script>
export default {
    data: () => ({
        listGroupValue: null,
    }),
    created() {

    },
    watch: {},
    computed: {
        windowWidth() {
            return this.$store.state.app.windowWidth;
        },
        showMenuExpand() {
            return this.$store.state.app.showMenuExpand;
        },
        downloadedFilesLength() {
            return this.$store.state.app.downloadedFiles.length;
        },
      unreadAmount() {
        const unreadAmount = this.$store.state.notifications.unreadAmount;
        let returnedUnreadAmount = 0;

        if (unreadAmount) returnedUnreadAmount = unreadAmount > 99 ? returnedUnreadAmount = '99+' : unreadAmount
        return returnedUnreadAmount;
      },
    },
    methods: {
        setShowMenu() {
            this.$store.commit('SET_SHOW_MENU');
        },
        setShowNotificationsPanel() {
          this.$store.commit('SET_SHOW_NOTIFICATIONS_PANEL');
        },
        setShowMenuExpand() {
            this.$store.commit('SET_SHOW_MENU_EXPAND');
        },
        logout() {
            setTimeout(() => this.listGroupValue = null)
            this.$store.dispatch('logout');
        },
        openDownloads() {
            this.$store.commit('DEL_ALL_MINIMIZE_DOWNLOAD_FILE');
        },
    },
}
</script>
