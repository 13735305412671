export function downloadFile(data, filename, mime) {
  return new Promise(resolve => {
    const blob = new Blob([data], {type: mime || 'application/octet-stream'});

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
      return;
    }

    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');

    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);

    tempLink.click();

    document.body.removeChild(tempLink);

    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 100);
    setTimeout(() => resolve(), 200);
  })
}

