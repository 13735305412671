export default axios => ({
  getPages(params) {
    return axios.get('/admin/pages', {params})
  },
  getPage(params) {
    return axios.get(`/admin/pages/${params.id}`, {params})
  },
  postPage(params) {
    return axios.post('/admin/pages', params)
  },
  putPage(params) {
    return axios.post(`/admin/pages/${params.get('id')}`, params)
  },
  deletePage(params) {
    return axios.delete(`/admin/pages/${params.id}`)
  },
  attachEntitiesPage(params) {
    return axios.post(`/admin/pages/${params.id}/attach`, params)
  },
});
