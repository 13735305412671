<style lang="scss">
    @import 'styles.scss';
</style>

<template>
    <v-snackbar :value="hashChanged"
                multi-line
                :timeout="-1"
                top
                color="primary"
                class="update-modal"
                app
    >
        <div class="text-subtitle-2 font-weight-regular">
            An update is available. Please save all current work and click update.
            <br/>
            You can also accept these updates by refreshing your browser at any
            <br/>
            time. Not updating may result in errors.
        </div>
        <template v-slot:action="{ attrs }">
            <v-btn
                    small
                    v-bind="attrs"
                    color="success"
                    @click="reloadApp"
                    elevation="0"
            >
                Update
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
  export default {
    props: {
      hashChanged: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {}
    },
    methods: {
      reloadApp() {
        this.$emit('reloadApp');
      },
    }
  }
</script>
