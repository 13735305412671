import store from "@/store";

export default axios => ({
  exportSales(param) {
    const controller = new AbortController(),
        signal = controller.signal,
        params = param.params,
        data = param.data;
    let totalSize = param.data.totalSize;
    store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: -1, rate: 0, loaded: 0}, {controller: controller}));
    return axios.get('/admin/exports/products/sales', {params, signal, responseType: 'blob', timeout: 0, onDownloadProgress: function (axiosProgressEvent) {
        if (axiosProgressEvent.rate) {
          const percentage = Math.round((axiosProgressEvent.loaded * 100) / totalSize)
          store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: percentage, rate: axiosProgressEvent.rate, loaded: axiosProgressEvent.loaded}, {controller: controller}));
        }
      }})
  },
  exportDetailedSales(param) {
    const controller = new AbortController(),
        signal = controller.signal,
        params = param.params,
        data = param.data;
    let totalSize = param.data.totalSize;
    store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: -1, rate: 0, loaded: 0}, {controller: controller}));
    return axios.get('/admin/exports/products/detailed-sales', {params, signal, responseType: 'blob', timeout: 0, onDownloadProgress: function (axiosProgressEvent) {
        if (axiosProgressEvent.rate) {
          const percentage = Math.round((axiosProgressEvent.loaded * 100) / totalSize)
          store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: percentage, rate: axiosProgressEvent.rate, loaded: axiosProgressEvent.loaded}, {controller: controller}));
        }
      }})
  },
  exportStudentsSales(param) {
    const controller = new AbortController(),
        signal = controller.signal,
        params = param.params,
        data = param.data;
    let totalSize = param.data.totalSize;
    store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: -1, rate: 0, loaded: 0}, {controller: controller}));
    return axios.get('/admin/exports/users/students-sales', {params, signal, responseType: 'blob', timeout: 0, onDownloadProgress: function (axiosProgressEvent) {
        if (axiosProgressEvent.rate) {
          const percentage = Math.round((axiosProgressEvent.loaded * 100) / totalSize)
          store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: percentage, rate: axiosProgressEvent.rate, loaded: axiosProgressEvent.loaded}, {controller: controller}));
        }
      }})
  },
  exportStudents(param) {
    const controller = new AbortController(),
        signal = controller.signal,
        params = param.params,
        data = param.data;
    let totalSize = param.data.totalSize;
    store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: -1, rate: 0, loaded: 0}, {controller: controller}));
    return axios.get('/admin/exports/users/students-details', {params, signal, responseType: 'blob', timeout: 0, onDownloadProgress: function (axiosProgressEvent) {
        if (axiosProgressEvent.rate) {
          const percentage = Math.round((axiosProgressEvent.loaded * 100) / totalSize)
          store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: percentage, rate: axiosProgressEvent.rate, loaded: axiosProgressEvent.loaded}, {controller: controller}));
        }
      }})
  },
});
