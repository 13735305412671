export default {
  state: {
    coursePackagesAll: [],
  },
  actions: {
    async getCoursePackages (state, params) {
      return await this._vm.$api.coursePackages.getCoursePackages(params)
    },
    async getCoursePackage (state, params) {
      return await this._vm.$api.coursePackages.getCoursePackage(params)
    },
    async postCoursePackage (state, params) {
      return await this._vm.$api.coursePackages.postCoursePackage(params)
    },
    async putCoursePackage (state, params) {
      return await this._vm.$api.coursePackages.putCoursePackage(params)
    },
    async deleteCoursePackage (state, params) {
      return await this._vm.$api.coursePackages.deleteCoursePackage(params)
    },
    async getCoursePackagesAll ({ commit }, params) {
      try {
        const param = Object.assign({}, params, {
          count: 'nolimit'
        });
        if (param.with) {
          param.with.push('specialities');
        } else {
          param.with = ['specialities'];
        }

        const res = await this._vm.$api.coursePackages.getCoursePackages(param);
        let data = res.data.data ? res.data.data : res.data;
        data.forEach(coursePackage => {
          let sort = true;
          coursePackage.courses?.forEach(course => {
            if (course.events) course.events = course.events.sort((a,b) => this._vm.$moment(a.start_date).diff(this._vm.$moment(b.start_date)))
            if (!course.pivot || (!course.pivot.position && course.pivot.position !== 0)) sort = false;
          })
          if (coursePackage.courses && sort) coursePackage.courses.sort((a,b) => a.pivot.position - b.pivot.position);
        })
        commit('SET_COURSE_PACKAGES_ALL', data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_COURSE_PACKAGES_ALL (state, data) {
      state.coursePackagesAll = data;
    },
  },
  getters: {
  },
};
