export default {
  state: {
    unreadAmount: 0,
  },
  actions: {
    async getNotifications (state, params) {
      return await this._vm.$api.notifications.getNotifications(params)
    },
    async getNotification (state, params) {
      return await this._vm.$api.notifications.getNotification(params)
    },
    async postNotification (state, params) {
      return await this._vm.$api.notifications.postNotification(params)
    },
    async putNotification (state, params) {
      return await this._vm.$api.notifications.putNotification(params)
    },
    async deleteNotification (state, params) {
      return await this._vm.$api.notifications.deleteNotification(params)
    },
    async markReadNotification (state, params) {
      return await this._vm.$api.notifications.markReadNotification(params)
    },
    async markUnreadNotification (state, params) {
      return await this._vm.$api.notifications.markUnreadNotification(params)
    },
    async markReadAllNotification () {
      return await this._vm.$api.notifications.markReadAllNotification()
    },
    async getUnreadAmountNotifications ({ commit }, params) {
      try {
        const utcOffset = this._vm.$moment().utcOffset();

        const param = Object.assign({}, params, {
          count: 1,
          remindDate: 'desc',
          utcOffset: utcOffset ? utcOffset/60 : 0,
        });

        const res = await this._vm.$api.notifications.getNotifications(param);
        const notifications = res.data.data ? res.data.data : res.data;
        if (notifications && notifications[0]) commit('SET_UNREAD_AMOUNT', notifications[0].unread_amount);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_UNREAD_AMOUNT (state, value) {
      state.unreadAmount = value || 0;
    },
  },
  getters: {
  },
};
