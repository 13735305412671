export default {
  state: {
  },
  actions: {
    async getTransactions (state, params) {
      return await this._vm.$api.transactions.getTransactions(params)
    },
    async getTransaction (state, params) {
      return await this._vm.$api.transactions.getTransaction(params)
    },
    async postTransactionRefund (state, params) {
      return await this._vm.$api.transactions.postTransactionRefund(params)
    },
    async postTransactionRequestRefund (state, params) {
      return await this._vm.$api.transactions.postTransactionRequestRefund(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
