export function highlightText(text, search) {
  const searchInput = (search || '').toString().toLocaleLowerCase()
  const index = text.toLocaleLowerCase().indexOf(searchInput)

  if (index < 0) return text;

  const start = text.slice(0, index) || '';
  const middle = text.slice(index, index + searchInput.length) || '';
  const end = text.slice(index + searchInput.length) || '';

  return `${start}<span class="v-list-item__mask">${middle}</span>${end}`;
}

