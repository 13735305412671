import Cookies from 'js-cookie';
import {router} from '@/router';

const token = Cookies.get('RTT');
const refresh_token = localStorage.getItem('RRTT');
const token_admin = Cookies.get('RTTA');
const refresh_token_admin = localStorage.getItem('RRTTA');
const stay_signed_in = Cookies.get('staySignedIn');

export default {
  state: {
    token: token,
    refreshToken: refresh_token,
    tokenAdmin: token_admin,
    refreshTokenAdmin: refresh_token_admin,
    staySignedIn: stay_signed_in,
    user: {},
  },
  actions: {
    async setLogin({ commit }, data) {
      try {
        let res = await this._vm.$api.user.setLogin(data);
        res.data.staySignedIn = data.staySignedIn;
        commit('SET_LOGIN', res.data);

        this._vm.$broadcastChannel.postMessage({type: 'login', payload: res.data});
        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'main'});

        return res;
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    async setRegister({ commit, dispatch }, data) {
      try {
        const res = await this._vm.$api.user.setRegister(data);

        await dispatch('setLogin', {email: data.email, password: data.password, staySignedIn: true})

        return res;
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    async getMe({ commit, dispatch }) {
      try {
        const res = await this._vm.$api.user.setMe();
        commit('SET_USER', res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error.response.data || 'Error', type: 'error'});
        dispatch('logout');
        throw error;
      }
    },
    async refreshToken({ commit }, data) {
      try {
        const res = await this._vm.$api.user.refreshToken(data);
        if (res.data?.token && res.data?.refresh_token) {
          commit('SET_LOGIN', res.data);

          return res
        } else {
          commit('SET_SNACK', {text: 'Internal Server Error', type: 'error'});
          throw {data: {message: 'Internal Server Error'}};
        }
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    logout({ commit }) {
      commit('SET_PRELOADER_APP', true);
      commit('SET_LOGOUT');
      commit('SET_WINDOW_WIDTH', window.innerWidth);
      setTimeout(() => commit('SET_PRELOADER_APP', false), 1000);

      if (router?.history?.current?.name !== 'login') {
        router.push({
          name: 'login'
        });
      }

      this._vm.$broadcastChannel.postMessage({type: 'logout'});
      this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'login'});
    },
    async setReset (state, params) {
      return await this._vm.$api.user.setReset(params)
    },
    async setForgot (state, params) {
      return await this._vm.$api.user.setForgot(params)
    },
  },
  getters: {
    userInitials(state) {
      const user = state.user;
      let initials = '';

      if (user) {
        if (user.first_name) initials += user.first_name.split(/\s/, 1).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase()
        if (user.last_name) initials += user.last_name.split(/\s/, 1).reduce((response, word) => response += word.slice(0, 1), '').toUpperCase()
      }

      return initials
    }
  },
  mutations: {
    SET_LOGIN(state, data) {
      Cookies.set('RTT', data.token, data.staySignedIn || state.user.staySignedIn ? {expires: 365} : {});
      localStorage.setItem('RRTT', data.refresh_token);
      if (data.staySignedIn || state.user.staySignedIn) {
        Cookies.set('staySignedIn', true, {expires: 365});
        state.staySignedIn = true;
      }
      state.token = data.token;
      state.refreshToken = data.refresh_token;
    },
    SET_LOGOUT(state) {
      state.token = null;
      state.refreshToken = null;
      state.user = {};
      Cookies.remove('RTT');
      Cookies.remove('staySignedIn');
      Cookies.remove('RTTA');
      state.tokenAdmin = null;
      state.refreshTokenAdmin = null;
      localStorage.clear();
    },
    SET_ADMIN_CREDENTIALS(state, data) {
      Cookies.set('RTTA', data.token, {expires: 365});
      localStorage.setItem('RRTTA', data.refresh_token);
      state.tokenAdmin = data.token;
      state.refreshTokenAdmin = data.refresh_token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    DELETE_ADMIN_CREDENTIALS(state) {
      state.tokenAdmin = null;
      state.refreshTokenAdmin = null;
      Cookies.remove('RTTA');
      localStorage.removeItem('RRTTA');
    },
  }
};
