export default {
  state: {
  },
  actions: {
    async getBookings (state, params) {
      return await this._vm.$api.bookings.getBookings(params)
    },
    async getBooking (state, params) {
      return await this._vm.$api.bookings.getBooking(params)
    },
    async postBooking (state, params) {
      return await this._vm.$api.bookings.postBooking(params)
    },
    async putBooking (state, params) {
      return await this._vm.$api.bookings.putBooking(params)
    },
    async deleteBooking (state, params) {
      return await this._vm.$api.bookings.deleteBooking(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
