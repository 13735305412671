<template>
    <v-fade-transition>
        <div id="main-div"
             v-show="!preloaderApp"
             class="fill-height success5"
        >
            <ScrollTop v-if="loading"/>
            <NavMenu/>
            <Toolbar/>
            <NotificationPanel/>
            <v-main class="main-content-window fill-height">
                <div :class="{'pl-2': windowWidth >= 1264, 'pa-5': windowWidth >= 768}" class="pa-3 main-container" id="main-container" >
                  <transition name="fade-transition" mode="out-in">
                    <router-view></router-view>
                  </transition>
                </div>
            </v-main>
            <ImagePreview/>
        </div>
    </v-fade-transition>
</template>

<script>
import NavMenu from '@/main-components/nav-menu'
import Toolbar from '@/main-components/toolbar'
import ScrollTop from '@/main-components/scroll-top'
import NotificationPanel from '@/main-components/notifications-panel'

export default {
    components: {
        NavMenu,
        Toolbar,
        ScrollTop,
        NotificationPanel
    },
    data() {
        return {
            loading: false,
        }
    },
    created() {
    },
    mounted() {
        this.$store.dispatch('updateMenulist');
        this.$store.dispatch('updateBottomMenulist');
        this.load();
    },
    computed: {
        preloaderApp() {
            return this.$store.state.app.preloaderApp;
        },
        windowWidth() {
            return this.$store.state.app.windowWidth;
        },
    },
    watch: {
    },
    methods: {
        load() {
            const el = document.getElementsByClassName('v-main__wrap')[0];
            if (el) {
                el.setAttribute('id','main-wrap-el');
                this.loading = true;
            } else {
                setTimeout(() => this.load(), 300);
            }
        },
    },
}
</script>
