import store from "@/store";

export default axios => ({
  getFiles(params) {
    return axios.get('/admin/files', {params})
  },
  getFile(params) {
    return axios.get(`/admin/files/${params.id}`)
  },
  postFile(params) {
    return axios.post('/admin/files', params)
  },
  putFile(params) {
    return axios.put(`/admin/files/${params.id}`, params)
  },
  deleteFile(params) {
    return axios.delete(`/admin/files/${params.id}`)
  },
  downloadFile(param) {
    const controller = new AbortController(),
        signal = controller.signal,
        params = param.params,
        data = param.data;
    let totalSize = param.data.totalSize;
    store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: -1, rate: 0, loaded: 0}, {controller: controller}));
    return axios.get('/admin/files/download', {params, signal, responseType: 'blob', timeout: 0, onDownloadProgress: function (axiosProgressEvent) {
        if (axiosProgressEvent.rate) {
          const percentage = Math.round((axiosProgressEvent.loaded * 100) / totalSize)
          store.commit('SET_DOWNLOAD_FILE', Object.assign({}, data, {percentage: percentage, rate: axiosProgressEvent.rate, loaded: axiosProgressEvent.loaded}, {controller: controller}));
        }
      }})
  },
});
