export default {
  state: {
  },
  actions: {
    async getSettings (state, params) {
      return await this._vm.$api.settings.getSettings(params)
    },
    async getSetting (state, params) {
      return await this._vm.$api.settings.getSetting(params)
    },
    async postSetting (state, params) {
      return await this._vm.$api.settings.postSetting(params)
    },
    async putSetting (state, params) {
      return await this._vm.$api.settings.putSetting(params)
    },
    async deleteSetting (state, params) {
      return await this._vm.$api.settings.deleteSetting(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
