export default axios => ({
  getBookings(params) {
    return axios.get('/admin/bookings', {params})
  },
  getBooking(params) {
    return axios.get(`/admin/bookings/${params.id}`, {params})
  },
  postBooking(params) {
    return axios.post('/admin/bookings', params)
  },
  putBooking(params) {
    return axios.put(`/admin/bookings/${params.id}`, params)
  },
  deleteBooking(params) {
    return axios.delete(`/admin/bookings/${params.id}`)
  },
});
