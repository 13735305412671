export default axios => ({
  getOrders(params) {
    return axios.get('/admin/orders', {params})
  },
  postOrder(params) {
    return axios.post('/admin/orders', params)
  },
  getOrder(params) {
    return axios.get(`/admin/orders/${params.id}`, {params})
  },
  deleteOrder(params) {
    return axios.delete(`/admin/orders/${params.id}`)
  },
  completePaymentOrder(params) {
    return axios.post('/admin/orders/complete-payment', params)
  },
});
