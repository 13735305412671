export default {
  state: {
  },
  actions: {
    async getInvoices (state, params) {
      return await this._vm.$api.invoices.getInvoices(params)
    },
    async getInvoice (state, params) {
      return await this._vm.$api.invoices.getInvoice(params)
    },
    async postInvoice (state, params) {
      return await this._vm.$api.invoices.postInvoice(params)
    },
    async putInvoice (state, params) {
      return await this._vm.$api.invoices.putInvoice(params)
    },
    async deleteInvoice (state, params) {
      return await this._vm.$api.invoices.deleteInvoice(params)
    },
    async chargeInvoice (state, params) {
      return await this._vm.$api.invoices.chargeInvoice(params)
    },
    async completePaymentInvoice (state, params) {
      return await this._vm.$api.invoices.completePaymentInvoice(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};
