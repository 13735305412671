<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <v-navigation-drawer v-model="showNotificationPanel"
                       fixed
                       right
                       temporary
                       floating
                       class="elevation-main notifications-panel"
                       width="450"
                       id="navigation-drawer-notifications"
                       overlay-opacity=".2"
  >
    <confirm ref="confirm"></confirm>
    <template v-slot:prepend>
      <v-toolbar class="elevation-main"
                 color="white"
                 height="50"
      >
        <div>
          <v-btn icon
                 @click.stop="refreshNotifications"
                 class="mr-2"
                 :loading="loadingNotifications && paginationNotifications.page === 1 && !markAllReadLoading"
          >
            <template v-slot:loader>
              <v-progress-circular :indeterminate="true" size="18" width="2" color="success"></v-progress-circular>
            </template>
            <v-icon color="success">refresh</v-icon>
          </v-btn>
        </div>
        <v-toolbar-title>Notifications</v-toolbar-title>

        <v-spacer></v-spacer>

        <div>
          <v-fade-transition>
            <v-btn v-if="unreadAmount"
                   @click.stop="markAllRead"
                   color="primary"
                   text
                   small
                   class="mr-2"
                   :loading="markAllReadLoading"
            >
              <template v-slot:loader>
                <v-progress-circular :indeterminate="true" size="18" width="2"></v-progress-circular>
              </template>
              Mark all as read
            </v-btn>
          </v-fade-transition>
        </div>
        <div>
          <v-btn icon
                 @click.stop="setShowNotificationsPanel"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
    </template>

    <v-list three-line>
      <template v-for="(notification, index) in notifications">
        <v-list-item :key="notification.id" class="notifications-list"
        >
          <v-badge :value="!notification.read_at"
                   color="error"
                   overlap
                   dot
                   offset-x="26"
                   offset-y="26"
          >
            <v-list-item-avatar color="primary5" class="ml-0">

              <v-icon color="primary85"
              >

                {{ getNotificationIcon(notification) }}
              </v-icon>
            </v-list-item-avatar>
          </v-badge>
          <v-list-item-content>
            <v-list-item-title class="py-1 text-body-2" v-if="notification.user">
              Student:
              <a @click.stop="goToStudent(notification.user.id)" class="primary70--text primary5 text-capitalize text-body-2 pa-1 rounded">
                {{ `${notification.user.first_name} ${notification.user.last_name}` }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle class="text-body-2 pb-1">
              <template v-if="notification.json?.message_type === 'student_manual'">
                <span class="white-space-pre">{{ notification.json?.message || ''}}</span>
              </template>
              <template v-else-if="notification.json?.message_type === 'payment_fail'">
                <div class="error70--text">
                  {{ `Invoice #${notification.json?.invoice_details?.id || '-'} for $${notification.json?.invoice_details?.payment_amount ? Number(notification.json.invoice_details.payment_amount).toLocaleString('en-US', {minimumFractionDigits: Number(notification.json.invoice_details.payment_amount) % 1 === 0 ? 0 : 2, maximumFractionDigits: 2}) : '-'} payment failed` }}
                </div>
                <div>
                  Payment method:
                  <span v-if="notification.json?.payment_method_details" class="text-capitalize primary70--text">
                    {{ getPaymentMethod(notification.json.payment_method_details) }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div>
                  <span>Order from </span>
                  <a v-if="notification.json?.order_id && notification.json?.order_date"
                     @click.stop="goToOrder(notification.json?.order_id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{$moment(notification.json.order_date).format('MMM D, YYYY')}}</span>
                  </a>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'kajabi_access_fail'">
                <div class="error70--text">
                  Kajabi grant access error
                </div>
                <div class="text-no-wrap text-truncate">
                  Webhook URL:
                  <v-tooltip top eager v-if="notification.json?.course_details?.webhook_url">
                    <template v-slot:activator="{ on }">
                      <a v-on="on" @click.stop="copyCode(notification.json?.course_details?.webhook_url)"
                         class="primary70--text primary5 text-body-2 pa-1 rounded"
                      >
                        <span>{{notification.json?.course_details?.webhook_url}}</span>
                      </a>
                    </template>
                    Click to copy: {{notification.json?.course_details?.webhook_url}}
                  </v-tooltip>
                  <span v-else> - </span>
                </div>
                <div>
                  <span>Course: </span>
                  <a v-if="notification.json?.course_details?.id && notification.json?.course_details?.name"
                     @click.stop="goToCourse(notification.json?.course_details?.id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{notification.json?.course_details?.name || '-'}}</span>
                  </a>
                  <span v-else> - </span>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'payment_proceeded'">
                <div class="success70--text">
                  {{ `Invoice #${notification.json?.invoice_details?.id || '-'} for $${notification.json?.invoice_details?.payment_amount ? Number(notification.json.invoice_details.payment_amount).toLocaleString('en-US', {minimumFractionDigits: Number(notification.json.invoice_details.payment_amount) % 1 === 0 ? 0 : 2, maximumFractionDigits: 2}) : '-'} has been paid` }}
                </div>
                <div>
                  Payment method:
                  <span v-if="notification.json?.payment_method_details" class="text-capitalize primary70--text">
                    {{ getPaymentMethod(notification.json.payment_method_details) }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div>
                  <span>Order from </span>
                  <a v-if="notification.json?.order_id && notification.json?.order_date"
                     @click.stop="goToOrder(notification.json?.order_id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{$moment(notification.json.order_date).format('MMM D, YYYY')}}</span>
                  </a>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'invoice_refunded'">
                <div class="error70--text">
                  {{ `Invoice #${notification.json?.invoice_details?.id || '-'} for $${notification.json?.invoice_details?.payment_amount ? Number(notification.json.invoice_details.payment_amount).toLocaleString('en-US', {minimumFractionDigits: Number(notification.json.invoice_details.payment_amount) % 1 === 0 ? 0 : 2, maximumFractionDigits: 2}) : '-'} has been refunded` }}
                </div>
                <div v-if="notification.json?.refunded_amount">
                  Refunded amount:
                  <span class="text-capitalize primary70--text">
                    {{ `$${Number(notification.json.refunded_amount).toLocaleString('en-US', {minimumFractionDigits: Number(notification.json.refunded_amount) % 1 === 0 ? 0 : 2, maximumFractionDigits: 2})}` }}
                  </span>
                </div>
                <div v-if="notification.json?.refunded_by">
                  Refunded by:
                  <span class="text-capitalize font-weight-medium">
                    {{ `${notification.json.refunded_by}` }}
                  </span>
                </div>
                <div>
                  Payment method:
                  <span v-if="notification.json?.payment_method_details" class="text-capitalize primary70--text">
                    {{ getPaymentMethod(notification.json.payment_method_details) }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div>
                  <span>Order from </span>
                  <a v-if="notification.json?.order_id && notification.json?.order_date"
                     @click.stop="goToOrder(notification.json?.order_id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{$moment(notification.json.order_date).format('MMM D, YYYY')}}</span>
                  </a>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'invoice_marked_paid'">
                <div class="warning70--text">
                  {{ `Invoice #${notification.json?.invoice_details?.id || '-'} for $${notification.json?.invoice_details?.payment_amount ? Number(notification.json.invoice_details.payment_amount).toLocaleString('en-US', {minimumFractionDigits: Number(notification.json.invoice_details.payment_amount) % 1 === 0 ? 0 : 2, maximumFractionDigits: 2}) : '-'} marked paid automatically` }}
                </div>
                <div>
                  Payment method:
                  <span v-if="notification.json?.payment_method_details" class="text-capitalize primary70--text">
                    {{ getPaymentMethod(notification.json.payment_method_details) }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div>
                  <span>Order from </span>
                  <a v-if="notification.json?.order_id && notification.json?.order_date"
                     @click.stop="goToOrder(notification.json?.order_id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{$moment(notification.json.order_date).format('MMM D, YYYY')}}</span>
                  </a>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'booking_canceled'">
                <div class="warning70--text">
                  Booking has been cancelled
                </div>
                <div>
                  <span>Order from </span>
                  <a v-if="notification.json?.order_id && notification.json?.order_date"
                     @click.stop="goToOrder(notification.json?.order_id)"
                     class="primary70--text primary5 text-body-2 pa-1 rounded"
                  >
                    <span>{{$moment(notification.json.order_date).format('MMM D, YYYY')}}</span>
                  </a>
                </div>
                <div v-if="notification.json?.event_city && notification.json?.event_start_date">
                  <span>Event: </span>
                  <span class="font-weight-medium">{{notification.json.event_city}}, {{$moment(notification.json.event_start_date).format('MMM D, YYYY')}}</span>
                </div>
                <div>
                  <span>Cancelled by: </span>
                  <span class="font-weight-medium">{{notification.json?.user_name || 'System'}}</span>
                </div>
              </template>
              <template v-else-if="notification.json?.message_type === 'rep_change'">
                <div class="warning70--text">
                  Rep has been changed
                </div>
                <div>
                  <span>Changed by: </span>
                  <span class="font-weight-medium">{{notification.json?.changed_by || 'System'}}</span>
                </div>
                <div>
                  <span>Previous value: </span>
                  <span class="font-weight-medium">{{notification.json?.previous_rep || 'N/A'}}</span>
                </div>
                <div>
                  <span>New value: </span>
                  <span class="font-weight-medium">{{notification.json?.new_rep || 'N/A'}}</span>
                </div>
              </template>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption">Notification left by:
              <span class="font-weight-medium">
                {{ notification.json?.message_type === 'student_manual' && notification.json?.created_by ? notification.json.created_by : 'System' }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text v-if="notification.json?.remind_date" class="d-flex flex-column align-end font-size-11 mt-1">
              <span>{{$moment(notification.json.remind_date).format('MMM D, YYYY')}}</span>
              <span>{{$moment(notification.json.remind_date).format('hh:mm A')}}</span>
            </v-list-item-action-text>

            <v-btn v-if="!notification.read_at"
                   @click.stop="markRead(notification)"
                   color="primary"
                   text
                   x-small
                   class="mr-n2"
                   :loading="notification.markReadLoading"
            >
              Mark as read
              <template v-slot:loader>
                <v-progress-circular :indeterminate="true" size="14" width="2"></v-progress-circular>
              </template>
            </v-btn>
            <v-btn v-else
                   @click.stop="markUnread(notification)"
                   color="primary"
                   text
                   x-small
                   class="mr-n2"
                   :loading="notification.markUnreadLoading"
            >
              <template v-slot:loader>
                <v-progress-circular :indeterminate="true" size="14" width="2"></v-progress-circular>
              </template>
              Mark as unread
            </v-btn>
            <v-btn v-if="adminAccess"
                   @click.stop="deleteNotification(notification)"
                   color="error"
                   text
                   x-small
                   class="mr-n2"
                   :loading="notification.deleteLoading"
            >
              <template v-slot:loader>
                <v-progress-circular :indeterminate="true" size="14" width="2"></v-progress-circular>
              </template>
              Delete
            </v-btn>
            <v-tooltip v-else
                       top
                       eager
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn color="error"
                         text
                         x-small
                         class="mr-n2"
                         disabled
                  >
                    Delete
                  </v-btn>
                </div>
              </template>
              You're not allowed to perform this action
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index + 1 !== notifications.length || !allNotifications"
            :key="`${notification.id}-divider`"
            inset
        ></v-divider>
      </template>
      <div v-intersect.quiet="onIntersect" v-if="!allNotifications">
        <template v-for="(n, index) in 3">
          <v-skeleton-loader
              :key="index"
              type="list-item-avatar-three-line"
          ></v-skeleton-loader>
        </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    loadingNotifications: false,
    actualizeLoadingNotifications: false,
    markAllReadLoading: false,
    notifications: [],
    allNotifications: false,
    totalItemsNotifications: 0,
    totalPagesNotifications: 1,
    paginationNotifications: {
      itemsPerPage: 20,
      page: 1,
    },
  }),
  created() {
    this.$watch('paginationNotifications', this.watchPaginationNotifications, {
      deep: true
    })
  },
  mounted() {
    this.setUnreadAmountNotificationsInterval();
    this.getNotifications();
  },
  beforeDestroy() {
    clearInterval(this._timer1);
  },
  computed: {
    unreadAmount() {
      return this.$store.state.notifications.unreadAmount;
    },
    showNotificationPanel: {
      get() {
        return this.$store.state.app.showNotificationPanel;
      },
      set(newValue) {
        if (newValue !== this.showNotificationPanel) {
          this.$store.commit('SET_SHOW_NOTIFICATIONS_PANEL');
        }
      }
    },
    adminAccess() {
      return this.$store.state.user.user?.role === 'admin';
    },
  },
  watch: {
    showNotificationPanel(val) {
      if (val) {
        this.refreshNotifications();
      } else {
        this.$vuetify.goTo(0, { container: '#navigation-drawer-notifications .v-navigation-drawer__content', duration: 0 });
      }
    },
  },
  methods: {
    setShowNotificationsPanel() {
      this.$store.commit('SET_SHOW_NOTIFICATIONS_PANEL');
    },
    markAllRead() {
      this.markAllReadLoading = true;
      this.$store.dispatch('markReadAllNotification')
          .then(() => {
            this.refreshNotifications();
          })
          .catch(error => {
            this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
            this.markAllReadLoading = false;
          })
    },
    markRead(item) {
      this.$set(item, 'markReadLoading', true);
      this.$store.dispatch('markReadNotification', item)
          .then(() => {
            this.getNotification(item.id);
          })
          .catch(error => {
            this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
            this.$set(item, 'markReadLoading', false);
          })
    },
    markUnread(item) {
      this.$set(item, 'markUnreadLoading', true);
      this.$store.dispatch('markUnreadNotification', item)
          .then(() => {
            this.getNotification(item.id);
          })
          .catch(error => {
            this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
            this.$set(item, 'markUnreadLoading', false);
          })
    },
    deleteNotification(item) {
      this.$refs.confirm.open('Delete Notification', 'Are you sure?', {color: 'error', buttonText: 'Delete'})
          .then(confirm => {
            if (confirm) {
              this.$set(item, 'deleteLoading', true);
              this.$store.dispatch('deleteNotification', item)
                  .then(() => {
                    let index = this.notifications.findIndex(notification => notification.id === item.id);
                    if (index > -1) {
                      this.notifications.splice(index, 1);
                    }
                    this.actualizeNotifications();
                    this.$store.commit('SET_SNACK', {text: 'Notification successfully deleted', type: 'success'});
                  })
                  .catch(error => {
                    this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
                    this.$set(item, 'deleteLoading', false);
                  })
            }
          })
    },
    refreshNotifications() {
      this.paginationNotifications.page === 1 ? this.getNotifications() : this.paginationNotifications.page = 1;
      this.$vuetify.goTo(0, { container: '#navigation-drawer-notifications .v-navigation-drawer__content' });
    },
    getNotifications() {
      if (!this.actualizeLoadingNotifications) {
        this.loadingNotifications = true;

        const utcOffset = this.$moment().utcOffset();

        let params = {
          count: this.paginationNotifications.itemsPerPage,
          remindDate: 'desc',
          utcOffset: utcOffset ? utcOffset/60 : 0,
          page: this.paginationNotifications.page,
        };

        this.$store.dispatch('getNotifications', params)
            .then(res => {
              let notifications = res.data.data ? res.data.data : res.data;

              if (notifications && notifications[0]) this.$store.commit('SET_UNREAD_AMOUNT', notifications[0].unread_amount);

              this.notifications = this.paginationNotifications.page === 1 ? notifications : this.notifications.concat(notifications);

              this.totalItemsNotifications = res.data.total;
              this.totalPagesNotifications = res.data.last_page;
              this.allNotifications = this.totalItemsNotifications === this.notifications.length;
            })
            .catch(error => {
              this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
            })
            .finally(() => {
              this.markAllReadLoading = false;
              this.loadingNotifications = false;
            })
      } else {
        setTimeout(() => this.getNotifications(), 500)
      }
    },
    actualizeNotifications() {
      this.actualizeLoadingNotifications = true;

      const utcOffset = this.$moment().utcOffset();

      let params = {
        count: this.paginationNotifications.itemsPerPage * this.paginationNotifications.page,
        remindDate: 'desc',
        utcOffset: utcOffset ? utcOffset/60 : 0,
        page: 1,
      };

      this.$store.dispatch('getNotifications', params)
          .then(res => {
            let notifications = res.data.data ? res.data.data : res.data;

            if (notifications && notifications[0]) this.$store.commit('SET_UNREAD_AMOUNT', notifications[0].unread_amount);

            this.notifications = notifications;

            this.totalItemsNotifications = res.data.total;
            this.allNotifications = this.totalItemsNotifications === this.notifications.length;
          })
          .catch(error => {
            this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
          })
          .finally(() => {
            this.actualizeLoadingNotifications = false;
          })
    },
    getNotification(id) {
      if (id) {
        const utcOffset = this.$moment().utcOffset();

        let params = {
          id: id,
          remindDate: 'desc',
          utcOffset: utcOffset ? utcOffset/60 : 0,
        };
        this.$store.dispatch('getNotification', params)
            .then(res => {
              let index = this.notifications.findIndex(notification => notification.id === id);
              if (index > -1) {
                let notification = res.data;

                if (notification) this.$store.commit('SET_UNREAD_AMOUNT', notification.unread_amount);

                this.$set(this.notifications, index, notification);
              }
            })
            .catch(error => {
              this.$store.commit('SET_SNACK', {text: error.data || '', type: 'error'});
            })
      }
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        if (this.notifications.length < this.totalItemsNotifications && this.paginationNotifications.page < this.totalPagesNotifications) {
          this.$set(this.paginationNotifications, 'page', this.paginationNotifications.page + 1);
        }
      }
    },
    watchPaginationNotifications() {
      this.getNotifications();
    },
    goToStudent(studentId) {
      let params = {id: studentId};
      this.$router.push({
        name: 'user',
        params: params
      });
    },
    goToOrder(orderId) {
      if (orderId) {
        let params = {id: orderId};
        this.$router.push({
          name: 'order',
          params: params
        });
      }
    },
    goToCourse(courseId) {
      if (courseId) {
        let params = {id: courseId};
        this.$router.push({
          name: 'course',
          params: params
        });
      }
    },
    async copyCode(value) {
      await navigator.clipboard.writeText(value);
      this.$store.commit('SET_SNACK', {text: 'Copied!', type: 'success'});
    },
    getPaymentMethod(details) {
      let str = 'Live Card';
      switch (details.payment_method_type) {
        case 'bank_transfer':
          return 'Bank Transfer'
        case 'cash':
          return 'Cash'
        case 'bank_card':
          return 'Paid By Card'
        case 'do_finance':
          return 'DoFinance'
        default:
          if (details.last_four) str = `**** ${details.last_four}` ;
          if (details.payment_system) str = str + ` (${details.payment_system})`;
          return str
      }
    },
    setUnreadAmountNotificationsInterval() {
      clearInterval(this._timer1);
      // this.$store.dispatch('getUnreadAmountNotifications')
      this._timer1 = setInterval(() => {
        this.$store.dispatch('getUnreadAmountNotifications');
      }, 1000 * 60 * 5)
    },
    getNotificationIcon(notification) {
      let icon = 'notification_important'

      if (notification.json?.message_type) {
        switch (notification.json.message_type) {
          case 'student_manual':
            icon = 'account_circle';
            break;
          case 'kajabi_access_fail':
            icon = 'webhook';
            break;
          case 'payment_fail':
            icon = 'payments';
            break;
          case 'payment_proceeded':
            icon = 'payments';
            break;
          case 'invoice_refunded':
            icon = 'payments';
            break;
          case 'invoice_marked_paid':
            icon = 'payments';
            break;
          default:
            icon = 'notification_important'
        }
      }

      return icon;
    },
  }
}
</script>
